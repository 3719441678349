import * as React from 'react'
import Layout from "../components/layout"
import Seo from "../components/seo"

const Faq = () => (
  <Layout>
    <Seo title="FAQ" />
    <div className="container">
      <h1>Frequently Asked Questions</h1>

      <div className="faq_grid">
        <section className="faq_section">
          <h2>General</h2>
          <ul className="faqs">
            <li className="faq">
              <h3 className="faq_q">What if extra children join my school mid-year?</h3>
              <div className="faq_a">
                <p>BoomReader will not charge for taking out/replacing pupils mid-year</p>
              </div>
            </li>
            <li className="faq">
              <h3 className="faq_q">Do children have to log in to use the app?</h3>
              <div className="faq_a">
                <p>No - schools can use BoomReader as a specific teacher-parent application. If you have pupils in KS2, consider the positive benefits of pupils writing about what they read and the book review features.</p>
              </div>
            </li>
            <li className="faq">
              <h3 className="faq_q">Filtering Issues</h3>
              <div className="faq_a">
                <p>We have a list of all the websites that need to be whitelisted by your schools internet provider. Please email <a href="mailto:support@boomhub.app">support@boomhub.app</a> for more information</p>
              </div>
            </li>
            <li className="faq">
              <h3 className="faq_q">Can children be in multiple classes?</h3>
              <div className="faq_a">
                <p>Yes! You can add children to multiple classes- for example a year one class and a RWI class. Follow the teacher guide to help you.</p>
              </div>
            </li>
            <li className="faq">
              <h3 className="faq_q">Can teachers be in multiple classes?</h3>
              <div className="faq_a">
                <p>Yes! You can add as many teachers to each of your classes. Follow the BoomReader admin guide for more information.</p>
              </div>
            </li>
            <li className="faq">
              <h3 className="faq_q">How do I change my pupil’s login code?</h3>
              <div className="faq_a">
                <p>At the top of the pupil page click the 'i' icon to view the pupil's details. From here you can 'refresh' which will assign the child a new code.</p>
              </div>
            </li>
            <li className="faq">
              <h3 className="faq_q">Something isn’t working</h3>
              <div className="faq_a">
                <p>Sorry! Get in touch: <a href="mailto:support@boomhub.app">support@boomhub.app</a></p>
              </div>
            </li>
            <li className="faq">
              <h3 className="faq_q">I have an idea!</h3>
              <div className="faq_a">
                <p>Awesome! Get in touch: <a href="mailto:support@boomhub.app">support@boomhub.app</a></p>
              </div>
            </li>
          </ul>
        </section>
        <section className="faq_section">
          <h2>BoomReader FAQ's</h2>
          <ul className="faqs">
            <li className="faq">
              <h3 className="faq_q">How much is a BoomReader subscription?</h3>
              <div className="faq_a">
                <p>BoomReader has several plans - you can findthe up to date <a href="https://boomreader.co.uk/pricing/">pricing information here</a></p>
              </div>
            </li>
            <li className="faq">
              <h3 className="faq_q">How do the gems work in BoomReader?</h3>
              <div className="faq_a">
                <p>In BoomReader, children earn gems for logging their reading up to twice a day. This can be a log from a teacher, parent or child. The gems they earn are randomised each time (they receive between 1-3). Children also earn gems for completing book reviews. Teachers do not have to manually award gems, but can give extra gems by clicking the award gems button in the pupil page.</p>
              </div>
            </li>
            <li className="faq">
              <h3 className="faq_q">Is there any training for BoomReader?</h3>
              <div className="faq_a">
                <p>Yes! Every BoomReader school receives a FREE training session each year. We also offer wider training sessions throughout the year.</p>
              </div>
            </li>
            <li className="faq">
              <h3 className="faq_q">Are you on social media?</h3>
              <div className="faq_a">
                <p>Yes we are! Twitter: @BoomTasicUK and Facebook: BoomTasticUK</p>
              </div>
            </li>
          </ul>
        </section>
        <section className="faq_section">
          <h2>BoomWriter UK FAQ's</h2>
          <ul className="fag_section faqs">
            <li className="faq">
              <h3 className="faq_q">How much is a BoomWriter UK+ subscription?</h3>
              <div className="faq_a">
                <p>BoomWriter UK+ is an annual yearly subscription service charged at £1 per student based upon NOR. BoomWriter UK+ lets you create unlimited writing activities that your pupils can complete at school or home or anywhere with an internet connection!</p>
              </div>
            </li>
            <li className="faq">
              <h3 className="faq_q">Can we publish books on BoomWriter UK without a subscription?</h3>
              <div className="faq_a">
                <p>Absolutely! You can choose any of our book packs and complete those without a subscription, just purchase the book pack for the correct size of class (see the BoomWriter UK administrators guide for more details).</p>
              </div>
            </li>
            <li className="faq">
              <h3 className="faq_q">How do the gems work in BoomWriter UK?</h3>
              <div className="faq_a">
                <p>In BoomWriter UK, children earn gems for submitting writing and for voting on our StarCasting tool. You can award gems individually as you read pupils work too</p>
              </div>
            </li>
            <li className="faq">
              <h3 className="faq_q">Do we have to use StarCasting?</h3>
              <div className="faq_a">
                <p>Not always! The only time StarCasting is mandatory is multi-chapter books, where a winner is required within the context of the book. Single chapter writing (anthology type) books give you the option to turn StarCasting on or off.</p>
              </div>
            </li>
            <li className="faq">
              <h3 className="faq_q">Is there any training for BoomWriter UK?</h3>
              <div className="faq_a">
                <p>Yes! Every BoomWriter UK school receives a FREE training session each year. We also offer wider training sessions throughout the year.</p>
              </div>
            </li>
          </ul>
        </section>
      </div>

    </div>
  </Layout>
)

export default Faq