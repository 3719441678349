import * as React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import * as Icon from "react-feather"

import logo from '../images/boom-icon.png'

const Header = ({ siteTitle }) => (
  <header className="header">

    <div className="header_main">
      <a href={process.env.TEACHER_APP_URL} className="header_back">
        <Icon.ArrowLeftCircle />
        <img src={logo} alt="Boom Device" className="header_logo" />
        Back to App
      </a>
      <span className="txt-heavy">Help & Resources</span>
    </div>
    <nav className="header_nav">
      <Link to="/" className="header_nav_link">Home</Link>
      <Link to="/grand-tour" className="header_nav_link">Grand Tour</Link>
      <Link to="/guides" className="header_nav_link">Guides & Resources</Link>
      <Link to="/faq" className="header_nav_link">FAQ</Link>
      {/* <Link to="/training" className="header_nav_link">Training</Link> */}
      
    </nav>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
